/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/jquery/jquery@1.12.4/dist/jquery.min.js
 * - /gh/twbs/bootstrap@3.4.1/dist/js/bootstrap.min.js
 * - /gh/joeldbirch/superfish@1.7.10/dist/js/superfish.min.js
 * - /gh/gdsmith/jquery.easing@1.4.2/jquery.easing.min.js
 * - /gh/metafizzy/isotope@1.5.26/jquery.isotope.min.js
 * - /npm/flexslider@2.7.2/jquery.flexslider.min.js
 * - /gh/scaron/prettyphoto@3.1.6/js/jquery.prettyPhoto.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
